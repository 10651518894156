@import "../params";

.title {
  padding: 4px;
}

.header, .serial, .payment, .violator, .amount, .actions {
  display: flex;
}
.header {
  justify-content: space-between;
}
.headerItem {
  box-sizing: border-box;
  padding: 0 4px;
}
.serialItem, .paymentItem {
  width: 100%;
  padding: 8px 4px;
}
.amountItem {
  padding: 0 4px;
  &:nth-child(1) {
    width: 40%;
  }
  &:nth-child(2) {
    width: 30%;
  }
  &:nth-child(3) {
    width: 40%;
  }
}
.violatorItem {
  padding: 8px 4px;
}

.actionsItem {
  padding: 16px 4px;
  &:nth-child(2) {
    margin-left: 20px;
  }
}
.mailSent {
  color: $success;
  display: inline-flex;
  align-items: center;
  padding: 10px 12px;
}
.mailSentText {
  font-size: 12px;
  box-sizing: border-box;
  padding: 0 4px;
}

.mailSentIcon {
  position: relative;
  top: 2px;
  box-sizing: border-box;
  padding: 0 4px;
}

.redColor {
  color: $danger;
}
