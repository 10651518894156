@import "src/components/mixins";

.wrapper {

}
.container {
  box-sizing: border-box;
  padding: 0px 80px;
}
.header {
  text-align: center;
  padding-top: 60px;
}
.body {
  margin-top: 40px;
}
.image {
  width: 60%;
}
.appName {
  color: #050C33;
  font-weight: 700;
  font-size: 26px;
  margin-top: 16px;
}
.formContainer {

}
.submitButton {
  margin-top: 40px;
}
.footer {

}
.label {
  width: 100%;
  margin-top: 20px;
  color: #181C32;
  font-size: 12px;
  margin-bottom: 2px;
}
.formGroup {
  background-color: #F3F6F9;
  border-radius: 6px;
  display: flex;
  margin-bottom: 16px;
}
.formInput {
  &Icon {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &Field {
    @include input-base;
    border: none;
    background-color: transparent;
    width: 100%;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
    &:disabled {
      color: #a0a2ac;
    }
  }
}
