.empty {
  height: 100px;
}
.additionalViolation {
  display: flex;
  margin: 0 -2px;
  align-items: center;
  justify-content: space-between;
}
.additionalViolationItem {
  box-sizing: border-box;
  padding: 0 2px;
  &:nth-child(1) {
    width: 88%;
  }
  &:nth-child(2) {
    width: 12%;
  }
}
.additionalViolationAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.driverLicenseItem {
  margin-bottom: 10px;
}

.footer {
  display: flex;
  padding-top: 50px;
  justify-content: center;
}

.footer button {
  width: 60%;
}
.protocolGPSField {
  position: relative;
}
.protocolGPSButton {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 24px;
  padding: 2px 8px;
  outline: none;
}
