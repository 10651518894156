@import "../params";

.licenseCard {
  display: flex;
  flex-direction: column;
  background-color: $grey;
  padding: 10px 6px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.licenseType, .licenseStatus {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.licenseStatus {
  border-radius: 4px;
  border-left: 4px solid darken($darkGrey, 10%);
  background-color: $darkGrey;
  color: $grey;
  padding: 4px 4px;
}

.tintingCategory {
  font-size: 10px;
}

.serial {
  margin-top: 4px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
}
.dates {
  display: flex;
}
.dateFrom, .dateTo {
  width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
}
.dateToLabel {
  font-size: 12px;
  color: $darkGrey;
}
.dateToBody {
  font-size: 13px;
  font-weight: 700;
}
.active {
  background-color: $lightSuccess;
  .licenseStatus {
    border-left: 4px solid darken($success, 10%);
    background-color: $success;
    color: $lightSuccess;
  }
}
.overdue {
  background-color: lighten($warning, 30%);
  .licenseStatus {
    border-left: 4px solid darken($warning, 10%);
    background-color: $warning;
    color: lighten($warning, 30%);;
  }
}
.absent {
  background-color: lighten($danger, 30%);
  .licenseStatus {
    border-left: 4px solid darken($danger, 10%);
    background-color: $danger;
    color: lighten($danger, 30%);;
  }
}

.inactive {
  background-color: lighten($darkGrey, 30%);
  .licenseStatus {
    border-left: 4px solid darken($darkGrey, 10%);
    background-color: $darkGrey;
    color: lighten($darkGrey, 30%);;
  }
}
