.personDialog {
  display: flex;
  padding: 12px 2px;
}

.personDialogPhoto,
.personDialogName,
.personDialogDocumentItem,
.personDialogGender,
.personDialogCountry,
.personDialogRegion,
.personDialogDistrict,
.personDialogAddress,{
  box-sizing: border-box;
  padding: 0 2px;
}

.personDialogImg {
  width: 100%;
}

.personDialogPhoto {
  width: 18%;
}
.personDialogName {
  width: 82%;
}
.personDialogDocument {
  display: flex;
  margin-top: 12px;
}
.personDialogDocumentItem:nth-child(1) {
  width: 33%;
}
.personDialogDocumentItem:nth-child(2) {
  width: 29%;
}
.personDialogDocumentItem:nth-child(3) {
  width: 38%;
}

.personDialogBody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.personDialogGender {
  width: 22%;
}
.personDialogCountry {
  width: 78%;
}
.personDialogRegion {
  width: 35%;
  margin-top: 6px;
}
.personDialogDistrict {
  width: 65%;
  margin-top: 6px;
}
.personDialogAddress {
  width: 100%;
  margin-top: 6px;
}

.personDialogFooter {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.personDialogFooterItem {
  width: 50%;
}
