.form1 {
  box-sizing: border-box;
  padding-top: 20px;
}

.form1Wrapper {
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.form1WrapperItem {
  box-sizing: border-box;
  padding: 0 4px;
  width: 33.333333%;
}
.doubleInput {
}
.doubleInputLabel {
  width: 100%;
}
.doubleInputForm {
  display: flex;
  margin: 0 -1px;
}
.doubleInputFormSerial {
  box-sizing: border-box;
  padding: 0 1px;
  width: 33.3333333%;
}
.doubleInputFormNumber {
  box-sizing: border-box;
  padding: 0 1px;
  width: 66.66666666%;
}
