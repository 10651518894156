@import "../params";

.autocomplete-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(100, 100, 100, .4);
  z-index: 121;
  &-window {
    position: absolute;
    border-radius: 6px;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    box-sizing: border-box;
    padding: 18px 20px;
    z-index: 121;
    background-color: #fff;
    overflow: scroll;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    &-title {
      font-size: 26px;
      font-weight: 700;
    }
    &-close {
      cursor: pointer;
      width: 40px;
    }
  }

  &-results {
    margin-top: 20px;
    &-item {
      padding: 6px;
      margin-bottom: 4px;
      display: flex;
      font-size: 16px;
      border-radius: 4px;
      box-sizing: border-box;
      &:hover {
        background: lighten($primary, 40%);
      }
    }
    &-icon {
      margin-right: 6px;
      width: 20px;
      color: $primary;
    }
    &-selected {
      background: lighten($primary, 40%);
    }
  }
}
