@import "../params";

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(10, 42, 88, 0.6);
  overflow: scroll;
  padding-top: 70px;
  padding-bottom: 50px;
  z-index: 20;
}

.content {
  background-color: $white;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  box-sizing: border-box;
  padding: 10px 8px;
  border-radius: 18px;
}
.title {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}
.close {
  position: absolute;
  right: 10px;
  top: 8px;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
