.violatorForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 20px;
}

.violatorFormItem {}

.labelWithExtra {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.sectionHeader {
  margin-bottom: 12px;
}

.radio {
  width: 18px;
  height: 18px;
}

.selectItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  label {
    font-size: 18px;
    margin-left: 8px;
  }
}

.viewActions {
  display: flex;
  justify-content: space-between;
  margin: 30px -4px 20px;
}

.viewActionsItem {
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px;
}

