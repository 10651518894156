.topBar {
  height: 70px;
  background-color: #18203F;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.backContainer {
  text-align: left;
  width: 28%;
  box-sizing: border-box;
  padding: 0 4px;
}
.titleContainer {
  width: 44%;
  box-sizing: border-box;
  padding: 0 4px;
  text-align: center;
}
.extraContainer {
  width: 28%;
  box-sizing: border-box;
  padding: 0 4px;
  text-align: right;
}
.backButton {

}
.title {
  text-align: center;
  flex: 1 1 100%;
  font-weight: 700;
}
