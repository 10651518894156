@import "../../../components/params";

.foundPersons {

}
.foundPersonsHeader {
  margin-bottom: 10px;
  margin-top: 10px;
}
.foundPersonsBody {
  margin-bottom: 8px;
}
.foundPersonsCard {
  background-color: $white;
  box-sizing: border-box;
  padding: 6px 8px;
  border-radius: 6px;
}
.foundPersonsCardActions {
  display: flex;
  margin: 14px -4px 0;
}
.foundPersonsCardActionsItem {
  box-sizing: border-box;
  padding: 0 4px;
  &:nth-child(1) {
    width: 30%;
  }
  &:nth-child(2) {
    width: 70%;
  }
}
