.searchForm {
  display: flex;
  :global(.input-wrapper) {
    padding-bottom: 0;
  }
}

.photoUploadBody {
  display: flex;
  margin: 0 -4px;
}

.photoUploadBodyItem {
  box-sizing: border-box;
  padding: 4px;
  width: 50%;
}
.viewActions {
  display: flex;
  margin: 30px -4px 20px;
}
.viewActionsItem {
  box-sizing: border-box;
  padding: 0 4px;
  width: 100%;
}
