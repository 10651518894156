.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 130;
  background-color: rgba(255, 255, 255, .9);
}

.loader {
  font-size: 20px;
  color: #404045;
  text-align: center;
}
