@import './params';

@mixin input-base {
  border: 1px solid #BBB5C7;
  border-radius: 6px;
  padding: 10px 6px;
  font-size: 18px;
  box-sizing: border-box;
}
@mixin label-base {

}

@mixin get-card-color($element) {
  @each $type, $color in map-get($themes, $element) {
    &-#{$type} {
      border-left-color: lighten($color, 30%);
    }
  }
}
