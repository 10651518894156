.actions {
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
}

.actionsItem {
  width: 50%;
  box-sizing: border-box;
  padding: 4px;
}
