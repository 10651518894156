@import "../params";

.languageSelect {
  background-color: transparent;
  border: none;
  color: $purple;
  padding: 6px 0;
  margin-left: 16px;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
