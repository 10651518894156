.header, .region, .qualification, .violator, .actions {
  display: flex;
  justify-content: space-between;
}
.headerItem {
  box-sizing: border-box;
  padding: 0 4px;
}
.headerItem:nth-child(1) {
  width: 40%;
}
.headerItem:nth-child(2) {
  width: 30%;
}
.headerItem:nth-child(3) {
  width: 30%;
}
.regionItem {
  box-sizing: border-box;
  padding: 8px 4px;
  width: 50%;
}

.qualificationItem {
  box-sizing: border-box;
  padding: 0 4px;
}
.qualificationItem:nth-child(1) {
  width: 20%;
}

.qualificationItem:nth-child(2) {
  width: 55%;
}

.qualificationItem:nth-child(3) {
  width: 25%;
}

.violatorItem {
  box-sizing: border-box;
  padding: 0 4px;
}

.violatorItem:nth-child(1) {
  width: 75%;
}
.violatorItem:nth-child(2) {
  width: 25%;
}

.actionsItem {
  box-sizing: border-box;
  padding: 8px 4px;
}
