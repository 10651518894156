@import "../params";

.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmIcon {
  width: 14%;
  color: $warning;
  box-sizing: border-box;
}
.confirmTitle {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-top: 14px;
  margin-bottom: 14px;
}
.confirmFooter {
  display: flex;
  margin-top: 14px;
  margin-bottom: 14px;
  width: 100%;
  justify-content: space-between;
}
.confirmReject, .confirmSubmit {
  box-sizing: border-box;
  padding: 0 4px;
}
.confirmReject {
  width: 30%;
}
.confirmSubmit {
  width: 40%;
}
