.plate {
  display: flex;
  padding: 20px 0;
  margin: 0 -4px;
}
.plateItem {
  width: 50%;
  box-sizing: border-box;
  padding: 0 4px;
}
.otherLicenses {
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
}
.otherLicensesItem {
  width: 50%;
  box-sizing: border-box;
  padding: 4px;
  height: auto;
}
.mainData {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  padding: 16px 0;
}
.mainDataItem {
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(1) {
    width: 100%;
  }
  &:nth-child(2) {
    width: 30%;
  }
  &:nth-child(3) {
    width: 30%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 30%;
  }
  &:nth-child(6) {
    width: 30%;
  }
  &:nth-child(7) {
    width: 40%;
  }
}
