@import "../params";

.sticker {
  background-color: $lightDanger;
  display: flex;
  align-items: center;
  color: $white;
  border-radius: 4px;
  padding: 10px 10px;
}
.icon {
  width: 30px;
  margin-right: 8px;
}
.text {
  color: $white;
  font-weight: 700;
}
.button {
  margin-left: auto;
  background-color: transparent;
  border: none;
  color: $purple;
  font-weight: 700;
  font-size: 14px;
}
