@import "../params";

.tabs {
  background-color: $white;
  border-radius: 8px;
  padding: 2px 6px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #CCD6FF;
}
.tabsItem {
  font-size: 16px;
  color: $black;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 98%;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.tabsActive {
  background-color: #CCD6FF;
  color: #4A0DFB;
  font-weight: 700;
}
