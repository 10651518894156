@import "src/components/params";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerItem {
  box-sizing: border-box;
  padding: 2px 4px;
}
.label {
  display: flex;
  align-items: center;
}
.title {
  font-size: 13px;
  color: darkGrey;
  font-weight: 700;
  position: relative;
  top: -1px;
  margin-left: 6px;
}
.icon {
  color: darkGrey;
}

.actions {
  display: flex;
  text-align: right;
  justify-content: space-between;
}
.actionsItem {
  box-sizing: border-box;
  padding: 0 4px;
}

.empty {
  height: 100px;
}
