.document {
  display: flex;
  margin: 0 -2px;
}
.documentSn {
  width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
}
.ownership {
  width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
}
.documentSnInput {
  display: flex;
  margin: 0 -1px;
}
.documentSeries {
  box-sizing: border-box;
  padding: 0 1px;
  width: 35%;
}
.documentNumber {
  box-sizing: border-box;
  padding: 0 1px;
  width: 65%;
}
.vehicle {
  display: flex;
}
.vehicleType, .vehicleColor {
  width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
}
.ownerFio {
  display: flex;
  margin: 0 -2px;
}
.ownerFioItem {
  box-sizing: border-box;
  padding: 0 2px;
  width: 33.333333%;
}
.footer {
  display: flex;
  padding-top: 60px;
  justify-content: space-between;
}
.footerBack {
  width: 30%;
}
.footerSubmit {
  width: 50%;
}
