@import "../params";

.label {
  font-size: 13px;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: lighten($primary, 40%);
  border-left: 4px solid $primary;
  color: $primary;
}
.primary {
  background-color: lighten($primary, 40%) !important;
  border-left: 4px solid $primary !important;
  color: $primary !important;
}

.success {
  background-color: lighten($success, 44%) !important;
  border-left: 4px solid $success !important;
  color: $success !important;
}

.warning {
  background-color: lighten($warning, 44%) !important;
  border-left: 4px solid $warning !important;
  color: $warning !important;
}
.danger {
  background-color: lighten($danger, 44%) !important;
  border-left: 4px solid $danger !important;
  color: $danger !important;
}
.grey {
  background-color: lighten($default, 24%) !important;
  border-left: 4px solid $default !important;
  color: $default !important;
}
.info {
  background-color: lighten($purple, 24%) !important;
  border-left: 4px solid $purple !important;
  color: $purple !important;
}
