@import 'src/components/params';

.sign {
  &-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 0 10px;
    border-width: 2px;
    border-style: dashed;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    border-color: $muted;
    margin-top: 20px;
    &-icon {
      min-width: 44px;
      max-width: 46px;
      margin-right: 16px;
    }
    &-text {
      text-align: left;
      font-size: 14px;
      max-width: 50%;
      color: $primary;
      height: 120px;
      line-height: 120px;
      overflow: hidden;
      display: inline-block;
      img {
        width: 100%;
        margin: auto;
      }
    }
    &:focus {
      outline: none;
    }
  }
}
