.autocomplete-open-button {
  opacity: 0;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.inputContainer {
  position: relative;
}

.icon {
  color: #555555;
  position: absolute;
  top: 10px;
  right: 10px;
}

