.attorneyDialogForm {
  box-sizing: border-box;
  padding: 14px 6px;
}

.attorneyDialogInputContainer {
  display: flex;
}
.attorneyDialogSerial {
  width: 33.3333333%;
  padding: 0 4px;
  box-sizing: border-box;
}
.attorneyDialogSerialInput {
  display: flex;
  margin: 0 -.5px;
}
.attorneyDialogSerialInputS {
  width: 40%;
  box-sizing: border-box;
  padding: 0 .5px;
}
.attorneyDialogSerialInputN {
  width: 60%;
  box-sizing: border-box;
  padding: 0 .5px;
}
.attorneyDialogFooter {
  display: flex;
  justify-content: space-between;
}
.attorneyDialogCancel, .attorneyDialogSubmit {
  box-sizing: border-box;
  padding: 0 4px;
}
.attorneyDialogCancel {
  width: 30%;
}
.attorneyDialogSubmit {
  width: 50%;
}
