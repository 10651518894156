@import "src/components/params";

.punishmentDialog {

}
.punishmentDialogBody {

}

.punishmentDialogFooter {
  display: flex;
  margin: 0 -4px;
  justify-content: center;
}

.punishmentDialogFooterItem {
  width: 50%;
  box-sizing: border-box;
  padding: 4px;
}
.noData {
  display: flex;
  justify-content: center;
}
.spinner {
  display: flex;
  justify-content: center;
}

.headerText {
  color: $darkGrey;
}

.errorText {
  color: $danger;
}

.refreshButton {
  display: flex;
  justify-content: flex-end;
}
