@import '../params';
@import '../mixins';

.photoCardContainer {
  background-color: $white;
  box-sizing: border-box;
  padding: 8px 4px;
  border-radius: 6px;
  border-left-style: solid;
  border-left-width: 10px;
  @include get-card-color('card');
}
.photoCardBody {
  display: flex;
}
.cardImage {
  width: 120px;
  margin-right: 10px;
}
.cardTextContainer {

}
.cardTextColumn {
  display: flex;
}
.cardTextDate {
  width: 50%;
}
.cardTextConcurrence {
  width: 50%;
}

.cardTextRow {

}

.footer {
  display: flex;
  justify-content: flex-end;
}
