@import "../params";

.selectContent {
  border: 1px solid #E1F0FF;
  border-radius: 6px;
}
.wrapper {
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
}
.checked {
  border-color: $purple;
}
.radioContainer {
  box-sizing: border-box;
  padding: 0 4px;
  width: 8%;
}
.radioContent {
  box-sizing: border-box;
  padding: 0 4px;
  width: 92%;
  overflow: hidden;
}
