@import "../mixins";

.usernameWrapper, .passwordWrapper {
  background-color: #F3F6F9;
  border-radius: 6px;
  display: flex;
}
.input, .inputPassword {
  @include input-base;
  border: none;
  background-color: transparent;
  width: 100%;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  &:disabled {
    color: #a0a2ac;
  }
}
.label {
  width: 100%;
  margin-top: 20px;
  color: #181C32;
  font-size: 12px;
  margin-bottom: 2px;
}
.icon {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input:nth-child(2) {
  width: 20%;
}
.input:nth-child(3) {
  width: 70%;
  box-sizing: border-box;
  padding-left: 16px;
}
.icon {

}
.passwordWrapper {
}
