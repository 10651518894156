@import "../params";

.plate {
  display: inline-flex;
  border: 2px solid $black;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  border-radius: 4px;
}
.region {
  border-right: 1px solid $black;
  padding: 7px 6px 7px 6px;
}
.number {
  padding: 7px 6px 7px 6px;
}
.flag {
  padding: 7px 6px 7px 0;
}
