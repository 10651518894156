@import "src/components/params";

.card {
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid $grey;
  padding: 8px;
  margin-bottom: 16px;
}
.overdue {
  background-color: $pink;
}
.cardHeader {
  display: flex;
  align-items: center;
  margin: 0 -4px;
}
.dangerText {
  color: $danger;
  text-transform: uppercase;
}

.cardHeaderItem {
  width: 50%;
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(2) {
    text-align: right;
  }
}
.cardBody {
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
}
.cardBodyPenalty {
  padding: 4px;
  display: flex;
  width: 30%;
}
.cardBodyBetween {
  padding: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cardBodyFullItem {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}
.cardBodyItem {
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(1) {
    width: 50%;
  }
  &:nth-child(2) {
    width: 50%;
  }
}
.cardFooter {
  display: flex;
  justify-content: flex-end;
}

.overdueLabel {
  text-transform: uppercase;
  display: inline-flex;
  padding: 5px 10px 4px 10px;
  align-items: center;
  font-size: 18px;
  color: $danger;
  background-color: $white;
  border-radius: 8px;
}

.warningIcon {
  width: 20px;
  margin-right: 5px;
  color: $danger;
}
