.button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  border: none;
  border-radius: 6px;
}
.icon {

}
.text {
  font-size: 22px;
  font-weight: 700;
}
