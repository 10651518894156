@import "../../components/params";

.empty {
  height: 100px;
}

.actions {
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
}
.actionsItem {
  width: 50%;
  box-sizing: border-box;
  padding: 4px;
}

.viewActions {
  display: flex;
  justify-content: space-between;
  margin: 50px -4px 20px;
}

.viewActionsItem {
  width: 48%;
  box-sizing: border-box;
  padding: 0 4px;
}

.error {
  border: 1px solid #C62828;
  border-radius: 3px;
  color: #C62828;
}

.errorMessage {
  display: flex;
  justify-content: flex-end;
}

.scanBlock {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.footer {
  display: flex;
  padding-top: 60px;
  justify-content: space-between;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.numberImage {
  width: 300px;
  height: 135px;
}

.numberDialogForm {
  box-sizing: border-box;
  padding: 14px 6px;
}

.numberDialogFooter {
  display: flex;
  justify-content: space-between;
}
.numberDialogCancel, .numberDialogSubmit {
  box-sizing: border-box;
  padding: 0 4px;
}
.numberDialogCancel {
  width: 30%;
}
.numberDialogSubmit {
  width: 50%;
}
.numberInput {
  padding: 0 4px;
  box-sizing: border-box;
}

.fileWrapper {
  width: 100%;
}

.filesView {
  margin-top: 10px;
}

.fileItem {
  margin-top: 5px;
}
.fileValidateText {
  display: flex;
  justify-content: flex-end;
}

.texPassSelect {
  margin-bottom: 10px;
}

.texPassFooter {
  display: flex;
  justify-content: flex-end;
}
