.input {
  &-area {
    font-family: Roboto, sans-serif;
    resize: none;
    &-expand {
      &:focus {
        height: 200px;
      }
    }
  }
}
