@import "../params";

.text {

}
.h1 {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 10px;
}
.h2 {
  font-weight: 700;
  font-size: 22px;
}
.h3 {
  font-weight: 700;
  font-size: 17px;
}
.body1 {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
}
.body2 {
  font-size: 15px;
}
.subtitle1 {
  font-size: 10px;
  color: $darkGrey;
}
.subtitle2 {
  font-size: 11px;
  color: #666A80;
}
.centered {
  text-align: center;
}
.inline {
  display: inline-block;
}
