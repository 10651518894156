@import 'src/components/params';

.profile {
  display: flex;
}
.photo {
  width: 17%;
  box-sizing: border-box;
  padding: 0 4px;
}
.photoImg {
  width: 100%;
}
.name {
  width: 83%;
  box-sizing: border-box;
  padding: 0 4px;
}
.organ {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}
.organItem {
  width: 25%;
  box-sizing: border-box;
  padding: 0 4px;
}
.actions {
  display: flex;
  justify-content: space-between;
}
.actionButton {
  &:not(:first-child) {
    margin-left: 6px;
  }
}

.raidCard {
  background-color: #F8D7DA;
}

.raidAlertContainer {
  display: flex;
  align-items: center;
  color: $white;
  justify-content: space-between;
  background-color: $lightDanger;
  border-radius: 5px;
  padding: 6px;
  font-size: 12px;
}
.raidAlertItem {
  margin-left: 10px;
}
.raidAlertIcon {
  display: flex;
  align-items: center;
}
.icon {
  width: 24px;
}
