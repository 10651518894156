.menu {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
  flex-wrap: wrap;
  padding-top: 20px;
}
.meuItem {
  display: flex;
  width: 50%;
  box-sizing: border-box;
  padding: 4px 4px;
}
