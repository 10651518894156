$purple: #4A0DFB;
$lightBlue: #E1F0FF;
$white: #fff;
$black: #000;
$pink: #FEE2E2;
$lightGrey: #F2F7FA;
$grey: #E4E6EF;
$darkGrey: #8B8D9E;
$lightPurple: #CCD6FF;
$warning: #EF6C00;
$primary: #0d21fb;
$danger: #C62828;
$lightDanger: #F44336;
$success: #1F8B24;
$lightSuccess: #C8E6C9;
$default: #aeaeae;
$muted: #aeaeae;
$text-light: #FFFFFF;
$text-dark-blue: #050C33;
$text-grey-blue: #374763;

$themes: (
  card: (
    primary: $primary,
    danger: $danger,
    warning: $warning,
    success: $success,
    default: $muted,
  ),
)
