@import "../params";

.fileButton {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  position: relative;
  font-size: 15px;
  font-weight: 400 !important;
  height: 40px;
  background-color: $lightBlue;
  color: $black;
  svg {
    position: relative;
    top: 2px;
    width: 16px;
    height: 16px;
    color: #4A0DFB;
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
  &:disabled {
    cursor: not-allowed;
    background-color: lighten($lightBlue, 2%);
    color: lighten($black, 30%);
    svg {
      color: lighten(#4A0DFB, 30%);
    }
  }
}
.fileButtonText {
  position: relative;
  display: inline-block;
  font-size: 15px;
}
.fileInput {
  display: none;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
