@import '../params';

.input-calendar {
  &-wrapper {
    position: relative;
    .input-error & .input {
      border: 1px solid $danger;
    }
  }
  &-btn {
    border: none;
    position: absolute;
    right: 0;
    height: 100%;
    font-size: 24px;
    opacity: 0;
    z-index: 10;
    width: 40px;
  }
  &-fake {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 9;
    border: none;
    background-color: transparent;
  }
}
