@import "../params";

.fileInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}
.fileInputWrapper {
  position: relative;
  display: flex;
}
.fileInput {
  position: absolute;
  opacity: 0;
  width: .1px;
  height: .1px;
}
.fileInputLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $lightBlue;
  border-radius: 7px;
  color: $purple;
  padding: 16px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  transition: .4s;
}
.fileInputTip {
  color: red;
}

.removeButton {
  background: transparent;
  border: 1px solid grey;
  color: grey;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 5px;
  box-sizing: content-box;
  line-height: 1px;
  transition: .4s;
  &:hover {
    border: 1px solid red;
    color: red;
  }
}
.fileInputSelected {
  display: flex;
  word-break: break-word;
  margin-top: 10px;
}
.error {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: $danger;
}
