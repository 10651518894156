.document {
  display: flex;
  margin: 0 -2px;
}
.documentSn {
  width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
}
.documentType {
  width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
}
.documentSnInput {
  display: flex;
  margin: 0 -1px;
}
.documentSeries {
  box-sizing: border-box;
  padding: 0 1px;
  width: 35%;
}
.documentNumber {
  box-sizing: border-box;
  padding: 0 1px;
  width: 65%;
}

.footer {
  display: flex;
  padding-top: 30px;
  justify-content: space-between;
}
.footerBack {
  width: 30%;
}
.footerSubmit {
  width: 50%;
}

.searchCardItem {
  margin-bottom: 20px;
}

.empty {
  height: 100px;
}

.isAgree {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.isAgreeLabel {
  font-weight: 700;
}
.isAgreeRadio {
  margin: 0 0 0 20px;
  position: relative;
  top: 2px
}
.isAgreeInputLabel {
  margin-left: 6px;
  margin-right: 16px;
}
