.confirmWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
}

.infoIcon {
  color: #1890FF;
  width: 60px;
}

.infoText {
  font-size: 15px;
  text-align: center;
  color: #8B8D9E;
}
.articleText {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.amount {
  padding: 10px 20px;
  background-color: #FDE68A;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 70px 0 20px 0;
  width: 100%;
}

.confirmReject {
  width: 30%;
}

.confirmSubmit {
  width: 30%;
}
