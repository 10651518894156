@import "src/components/params";

.wanted {
  &Container {

  }
  &Card {
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid $grey;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    &Item {
      &:nth-child(1) {
        box-sizing: border-box;
        padding: 4px;
        width: 60%;
      }
      &:nth-child(2) {
        box-sizing: border-box;
        padding: 4px;
        width: 40%;
      }
      &:nth-child(3) {
        box-sizing: border-box;
        padding: 4px;
        width: 50%;
      }
      &:nth-child(4) {
        box-sizing: border-box;
        padding: 4px;
        width: 50%;
      }
      &:nth-child(5) {
        box-sizing: border-box;
        padding: 4px;
        width: 100%;
      }
      &:nth-child(6) {
        box-sizing: border-box;
        padding: 4px;
        width: 100%;
      }
    }
  }
}
