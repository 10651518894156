@import "src/components/params";

.validationErrors {
  box-sizing: border-box;
  padding: 8px 12px;
}
.validationErrorsIcon {
  margin: 0 auto;
  width: 16%;
  color: $warning;
}
.validationErrorsBody {
  margin-top: 6px;
}
.validationErrorDate, .validationErrorUser {
  font-size: 12px;
  color: $darkGrey;
  text-align: center;
  font-weight: 700;
}
.errorList {
  padding: 12px;
}
.errorListItem {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 700;
}
.errorListSub {
  font-weight: 400;
  color: $darkGrey;
  font-size: 11px;
}
