.violatorCard {
  box-sizing: border-box;
}
.wanted {
  border: 2px solid #F44336;
  border-radius: 12px;
}
.alertHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F44336;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  color: #FFF;
  font-size: 12px;
  padding: 2px;
}
.violatorCardHeader {
  display: flex;
}
.photo {
  width: 23%;
  box-sizing: border-box;
  padding: 0 4px;
}
.profile {
  width: 77%;
  box-sizing: border-box;
  padding: 0 4px;
}
.profileItem {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(3) {
    display: flex;
    justify-content: space-between;
  }
}
.img {
  width: 100%;
}
.violatorCardBody {
  padding: 0 4px;
}
.violatorCardBodyRow1,
.violatorCardBodyRow2,
.violatorCardBodyRow3 {
  display: flex;
  margin: 8px -4px;
}
.violatorCardBodyRow1Item {
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(1) {
    width: 25%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 50%;
  }
}

.violatorCardBodyRow2Item {
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(1) {
    width: 100%;
  }
}

.violatorCardBodyRow3Item {
  box-sizing: border-box;
  padding: 4px;
  &:nth-child(1) {
    width: 25%;
  }
  &:nth-child(2) {
    width: 75%;
  }
}
