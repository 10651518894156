@import "../../../../../components/params";

.selectExtra {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  margin: 4px 8px;
  border-radius: 4px;
}
.driverLicense {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 12px;
}
.driveLicenseLabel {
  display: flex;
  align-items: center;
}
.driveLicenseText {
  font-size: 13px;
  margin-left: 8px;
}
.driverLicenseActions {}
