@import "src/components/params";

.confiscated {
  margin-top: 20px;
}
.isAgree {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.isAgreeLabel {
  font-weight: 700;
  width: 30%;
}
.isAgreeRadio {
  margin: 0;
  position: relative;
  top: 2px
}
.isAgreeInputLabel {
  margin-left: 6px;
  margin-right: 16px;
}

.agreeText {
  color: darkGrey;
}
.isAgreeRadios {
  display: flex;
  width: 70%;
}

.isAgreeRadiosItem {
  box-sizing: border-box;
  padding: 0 8px;
  width: 50%;
  display: flex;
  align-items: flex-start;
}
