.fingerprint {
  @media (min-width: 992px) {
    width: 60%;
  }
  @media (min-width: 1200px) {
    width: 30%;
  }
  margin: 0 auto;
  height: 400px;
  border: 0.0625rem solid rgb(156, 156, 156);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  img {
    width: 50%;
  }
}
.finger-title {
  font-weight: 700;
  font-size: 20px;
}
