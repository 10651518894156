@import "../params";

.container {
  position: relative;
  width: 100%;
  height: 90px;
  border-radius: 6px;
  background-color: $lightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.inputFile {
  opacity: 0;
  background-color: red;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.removeButton {
  background-color: $lightBlue;
  border: none;
  width: 50px;
  height: 50px;
  z-index: 2;
  border-radius: 50%;
}

.uploadIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
}

.imagePreview {
  position: absolute;
  width: 100%;
}
.progress {
  background-color: rgba(255, 255, 255, .6);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressLoader {
  width: 56px;
  height: 56px;
}
