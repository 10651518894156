@import "src/components/params";

.card {
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid $grey;
  padding: 8px;
  margin-bottom: 16px;
}
.cardHeader {
  display: flex;
  align-items: center;
  margin: 0 -4px;
}

.cardHeaderItem {
  width: 50%;
  box-sizing: border-box;
  padding: 4px;
}
.cardHeaderBetween {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cardBody {
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
}
.cardBodyItem {
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
}
.cardBodyHalfItem {
  box-sizing: border-box;
  padding: 4px;
  width: 50%;
}
.cardFooter {
  display: flex;
  justify-content: flex-end;
}

.dangerText {
  color: $danger;
  text-transform: uppercase;
}
