.dialogForm {
  box-sizing: border-box;
  padding: 14px 6px;
}

.dialogFooter {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.dialogCancel, .dialogSubmit {
  box-sizing: border-box;
  padding: 0 4px;
}
.dialogCancel {
  width: 30%;
}
.dialogSubmit {
  width: 50%;
}
.numberInput {
  padding: 0 4px;
  box-sizing: border-box;
}

.fileWrapper {
  width: 100%;
}
