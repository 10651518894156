.selectContentHead, .selectContentBody {
  display: flex;
  flex-wrap: wrap;
}
.selectContentHeadItem {
  box-sizing: border-box;
  width: 100%;
  padding: 0 4px;
}
.selectContentBodyItem {
  box-sizing: border-box;
  padding: 0 4px;
  &:nth-child(1) {
    width: 42%;
  }
  &:nth-child(2) {
    width: 29%;
  }
  &:nth-child(3) {
    width: 29%;
  }
}
