@import "./components/params";
.input {
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px 6px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #555555;
  box-shadow: none;
  &-label {
    font-size: 11px;
    color: #666A80;
  }
  &-wrapper {
    padding-bottom: 16px;
    box-sizing: border-box;
    position: relative;
  }
  &:focus {
    border: 1px solid $primary;
    outline: none;
  }
  .input-error > & {
    border: 1px solid $danger;
    color: $danger;
    &:focus {
      border: 1px solid $danger;
      outline: none;
    }
    &-label {
      color: $danger;
    }
    &-error-text {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      color: $danger;
    }
  }
  &-error-text {
    display: none;
  }
  &-asterisk {
    color: $danger;
  }
  &:disabled {
    border: 1px solid $muted;
    background-color: $muted;
    color: $text-light;
    outline: none;
    cursor: not-allowed;
  }

  &-select {
    background-color: transparent;
  }
}

//.Toastify__toast-container {
//  width: 100%;
//  top: 0;
//}
//.Toastify__toast--info {
//  background: #1BC5BD;
//  color: #FFF;
//}
//.Toastify__toast--success {
//  background: #1F8B24;
//  color: #FFF;
//}
//.Toastify__toast--warning {
//  background: #ED9526;
//  color: #FFF;
//}
//.Toastify__toast--error {
//  background: #C62828;
//  color: #FFF;
//}
.Toastify__toast-body {
  font-size: 12px;
  word-break: break-word;
  //white-space: pre;
  padding: 6px;
}
