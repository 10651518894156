@import "../params";

.button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: .3s;
  position: relative;
  svg {
    position: relative;
    top: 2px;
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.buttonText {
}
.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normal {
  padding: 6px 20px;
  font-size: 15px;
  height: 34px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.small {
  padding: 4px 6px;
  svg {
    width: 13px;
    height: 13px;
  }
}
.medium {
  font-size: 12px;
  padding: 10px 12px;
  border-radius: 4px;
  max-height: 41px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.large {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  height: 40px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.transparent {
  border: 1px solid $purple;
  color: $purple;
  &:disabled {
    border: 1px solid lighten($purple, 30%);
    color: lighten($purple, 30%);
  }
}
.purple {
  background-color: $purple;
  color: $white;
  &:disabled {
    background-color: lighten($purple, 30%);
    //color: lighten($purple, 30%);
  }
}

.lightBlue {
  background-color: $lightBlue;
  color: $black;
  svg {
    color: #4A0DFB;
  }
  &:disabled {
    background-color: lighten($lightBlue, 2%);
    color: lighten($black, 30%);
    svg {
      color: lighten(#4A0DFB, 30%);
    }
  }
}

.action {
  background-color: $lightBlue;
  color: $purple;
  &:disabled {
    background-color: lighten($lightBlue, 2%);
    color: lighten($purple, 30%);
  }
}
.grey {
  background-color: $grey;
  color: $black;
  &:disabled {
    background-color: lighten($grey, 3%);
    color: lighten($black, 30%);
  }
}
.text {
  color: $purple;
  &:disabled {
    color: lighten($purple, 30%);
  }
}
.textWhite {
  color: $white;
}
.fullWidth {
  width: 100%;
}
.fullHeight {
  display: inline-block;
  height: 100%;
}

.fullWidth .purple {
  font-size: 17px;
}
